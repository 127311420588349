import { ref } from 'vue';

export interface ICountAnimationOptions {
  duration?: number;
  timingFunction?: (percent: number) => number;
  withPrecision?: boolean;
}

const FRAME_DURATION = 1000 / 60;

const defaultOptions: ICountAnimationOptions = {
  duration: 1000,
  // https://easings.net/
  timingFunction: (x) => 1 - Math.pow(1 - x, 4),
  withPrecision: false,
};

/**
 * Хук для плавного увеличения значения числа к переданному фиксированному
 * @param {ICountAnimationOptions} options - Направление адаптивности (mobile-first или desktop-first)
 */
export const useCountAnimation = (options = defaultOptions) => {
  const config = {
    ...defaultOptions,
    ...(options as Required<ICountAnimationOptions>),
  };
  const totalFrames = Math.round(config.duration / FRAME_DURATION);
  const count = ref<number>(0);
  const progress = ref<number>(0);
  let interval: ReturnType<typeof setTimeout>;

  function start(to: number, from = 0) {
    let frame = 0;
    count.value = from;

    if (interval) stop();

    interval = setTimeout(function tick() {
      frame++;
      progress.value = config.timingFunction(frame / totalFrames);
      const countStep = (to - from) * progress.value;
      const currentCount = config.withPrecision ? countStep : Math.round(countStep);

      if (Math.abs(to - from) === 1) {
        count.value = to;
      } else if (count.value !== currentCount) {
        count.value = currentCount + from;
      }

      if (frame !== totalFrames) {
        interval = setTimeout(tick, FRAME_DURATION);
      }
    }, 0);
  }

  function stop() {
    clearTimeout(interval);
    return count.value;
  }

  return { count, progress, start, stop };
};
